<template>
  <div>
    <div class="w-title">
      <h1 class="title">Charger un devis</h1>
      <p><b style="color: orangered">IMPORTANT</b> : En chargeant un devis, votre panier actuel sera écrasé par celui du
        devis chargé.</p>
    </div>

    <div class="container quote-load-content">

      <h1 class="subtitle">Veuillez entrer votre code référence à 10 caractères</h1>

      <b-field style="width: 33%"
               :type="{ 'is-danger': notExisting }"
               :message="{ 'Ce devis n\'existe pas.': notExisting }">
        <b-input placeholder="Entrez le code référence"
                 icon="lock" maxlength="10"
                 v-model="token">
        </b-input>
      </b-field>

      <vue-recaptcha ref="recaptcha"
                     @verify="captchaVerified"
                     @expired="captchaExpired"
                     :sitekey="getCaptchaSiteKey"
                     :loadRecaptchaScript="true">
      </vue-recaptcha>

      <b-button size="is-large" :disabled="!captchaStatus || buttonClicked || token.length !== 10"
                @click="proceedQuoteSend" style="margin-top: 25px">Charger mon devis
      </b-button>

    </div>


  </div>
</template>

<script>
import VueRecaptcha from "vue-recaptcha";
import {mapGetters} from "vuex";

export default {
  name: "QuoteLoad",
  components: {VueRecaptcha},
  computed: {
    ...mapGetters("data", ["getCaptchaSiteKey"])
  },
  mounted() {
    if (!this.$route.params.token) return
    this.token = this.$route.params.token.substring(0, 10)
  },
  data() {
    return {
      captchaStatus: false,
      captchaToken: '',
      buttonClicked: false,
      token: '',
      notExisting: false
    }
  },
  methods: {
    captchaVerified(token) {
      this.captchaToken = token
      this.captchaStatus = true
    },
    captchaExpired() {
      this.captchaStatus = false
      this.captchaToken = ''
      this.$refs.recaptcha.reset()
    },
    proceedQuoteSend() {
      if (!this.captchaStatus || this.captchaToken === '' || this.buttonClicked)
        return

      this.buttonClicked = true

      this.$store.dispatch('quote/load', {
        captchaToken: this.captchaToken,
        token: this.token
      }).catch((e) => {
        let message = 'Une erreur est survenue: ' + e.message

        if (e.response && e.response.status === 404) {
          message = 'Ce devis n\'existe pas.'
          this.notExisting = true
        }

        this.$buefy.snackbar.open({
          message: message,
          type: 'is-danger',
          duration: 5000
        })
        this.captchaExpired()
      }).finally(() => {
        this.buttonClicked = false
      })
    }
  }
}
</script>

<style scoped>
.quote-load-content {
  margin-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
</style>